<template>
  <div class="min_h_100_vh dis_flex flex_dir_col bg_col_F5F7F9">
    <!-- 标题 -->
    <div class="pos_rel">
      <!-- 背景 -->
      <img
        class="w_100_per dis_flex"
        src="http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/lzikTiKuMekQSGdylAro.svg"
      />

      <!-- 内容 -->
      <div class="pos_abs l_0 t_0 w_100_per dis_flex flex_dir_col">
        <div class="font_24 col_fff l_h_100_per m_t_32 m_l_16">业绩数据</div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!-- 收缩 -->
    <div class="m_t_neg_100"></div>

    <!-- 统计 -->
    <Statistic class="pos_rel" />

    <!-- 进件统计 -->
    <PrepareStatistic class="m_t_16" />

    <!-- 审批统计 -->
    <ApproveStatistic class="m_t_16" />

    <!-- 待放款统计 -->
    <WaitLoanStatistic class="m_t_16" />

    <!-- 合伙人列表 -->
    <LeaderList class="m_t_16" />

    <!-- 留白 -->
    <div class="h_32"></div>

    <!--  -->
  </div>
</template>

<script>
export default {
  components: {
    Statistic: () => import('./components/Statistic.vue'),
    PrepareStatistic: () => import('./components/PrepareStatistic.vue'),
    ApproveStatistic: () => import('./components/ApproveStatistic.vue'),
    WaitLoanStatistic: () => import('./components/WaitLoanStatistic.vue'),
    LeaderList: () => import('./components/LeaderList.vue'),
  },
  data() {
    return {
      loanGeneralStatistics: {},

      //
    };
  },
  computed: {},
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>
